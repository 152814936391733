import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import UniversalCard from '../../components/molecules/UniversalCard';
import CFS from '../../components/molecules/CFS';

import imgI1 from '../../assets/images/pages/graduatos-de-ele-barcelona/icon1.svg';
import imgI2 from '../../assets/images/pages/graduatos-de-ele-barcelona/icon2.svg';
import imgI3 from '../../assets/images/pages/graduatos-de-ele-barcelona/icon3.svg';
import imgI4 from '../../assets/images/pages/graduatos-de-ele-barcelona/icon4.svg';
import imgI5 from '../../assets/images/pages/graduatos-de-ele-barcelona/icon5.svg';
import imgTemplate from '../../assets/images/pages/graduatos-de-ele-barcelona/template.png';

import '../../assets/styles/pages/graduatos-de-ele-barcelona.scss';

const GraduatosDeEleBarcelona = ({ data }) => {
  const allPrismicGraduates = data.allPrismicGraduates.edges;

  return (
    <Layout
      title="Graduados de ELE Barcelona"
      description="Conoce a nuestros graduados y sus éxitos profesionales. Anímate como ellos y da un cambio a tu vida profesional para convertirte en profesor de español."
    >
      <div className="graduatos-de-ele-barcelona">
        <CFS
          title="Graduados de ELE Barcelona"
          description="Nos encanta mantenernos en contacto con nuestros profesores y profesoras graduados y compartir sus éxitos profesionales después de realizar el curso FELE."
        />
        <section className="second-section c-section-py-80">
          <div className="wrapper container">
            <div className="block_l">
              <h3 className="c-title-34">Entre todos destacan:</h3>
            </div>
            <div className="block_r">
              <div className="benefit">
                <img src={imgI1} alt="" />
                <div className="benefit__text">
                  <p className="c-text-14">
                    Su preferencia por la escuela al tener un enfoque práctico con estudiantes
                    reales.
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgI2} alt="" />
                <div className="benefit__text">
                  <p className="c-text-14">
                    Un acompañamiento personalizado antes y después de la formación.
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgI3} alt="" />
                <div className="benefit__text">
                  <p className="c-text-14">Sentirse cómodos y confiados en el aula.</p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgI4} alt="" />
                <div className="benefit__text">
                  <p className="c-text-14">
                    Una escuela internacional y familiar en pleno centro de la ciudad.
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgI5} alt="" />
                <div className="benefit__text">
                  <p className="c-text-14">
                    Un taller profesional personalizado y adaptado a sus necesidades.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="third-section__title c-title-34">
                  Opiniones de nuestros nuevos profesores
                </h2>
              </div>
            </div>

            <div className="row">
              {allPrismicGraduates?.map(({ node }) => (
                <div className="col-sm-6 col-lg-4" key={node.id}>
                  <UniversalCard
                    title={node?.data?.title?.text}
                    description={node?.data?.description?.text}
                    img={imgTemplate}
                  >
                    <iframe
                      width="560"
                      height="315"
                      src={node?.data?.video?.embed_url
                        .replace('youtu.be/', 'www.youtube.com/embed/')
                        .replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/')}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </UniversalCard>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicGraduates(sort: { order: DESC, fields: last_publication_date }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            description {
              text
            }
            video {
              embed_url
            }
          }
        }
      }
    }
  }
`;

export default GraduatosDeEleBarcelona;
