import { Link } from 'gatsby';
import React from 'react';

import iconTemp from '../../assets/images/pages/home-page/c1.png';

function UniversalCard({
  withShadow = false,
  title,
  description,
  btn = {},
  img,
  notice = '',
  children = false,
}) {
  return (
    <div className={`u-card ${withShadow ? 'with-shadow' : ''}`}>
      <div className="card-head">
        {!children ? (
          <>
            <img src={img} alt={title} />
            {notice.length ? <div className="notice">{notice}</div> : null}
          </>
        ) : (
          children
        )}
      </div>
      <div className="card-body">
        <h4 className="title">{title}</h4>
        {description && (
          <p className="description" dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {btn.hasOwnProperty('to') && btn.hasOwnProperty('text') ? (
          <Link to={btn.to || '/'} className="c-btn c-btn--green">
            {btn.text}
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default UniversalCard;
